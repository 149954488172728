import React, { FC } from 'react';
import { Web3Provider } from './Web3Provider';
import { Main } from './Main';
import { ChakraProvider } from '@chakra-ui/react';
import * as buffer from "buffer";
import { theme } from '../mainTheme';
import "@fontsource/courier-prime";
import { coinAddresses, CoinAddressInterface, cryptoLogos, explorerCLusters, SupportedAsset } from '../common';
window.Buffer = buffer.Buffer;
require('../css/App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const NETWORK_TYPE = process.env.REACT_APP_NETWORK_TYPE || 'DEVNET';
export const addresses: CoinAddressInterface = coinAddresses[NETWORK_TYPE];
export const EXPLORER_CLUSTER: string = explorerCLusters[NETWORK_TYPE];

export const assetsSupported: SupportedAsset[] = [
    { value: 'USDC', label: 'USDC', img: cryptoLogos.USDC, mint: addresses.usdc, decimals: 6 },
    { value: 'SOL', label: 'SOL', img: cryptoLogos.SOL, mint: addresses.sol, decimals: 9 },
    { value: 'USDT', label: 'USDT', img: cryptoLogos.USDT, mint: addresses.usdt, decimals: 6 },
    { value: 'ALEPH', label: 'ALEPH', img: cryptoLogos.ALEPH, mint: addresses.aleph, decimals: 8 },
    { value: 'AURY', label: 'AURY', img: cryptoLogos.AURY, mint: addresses.aury, decimals: 9 },
    { value: 'BILLY', label: 'BILLY', img: cryptoLogos.BILLY, mint: addresses.billy, decimals: 6 },
    { value: 'BLZE', label: 'BLZE', img: cryptoLogos.BLZE, mint: addresses.blze, decimals: 9 },
    { value: 'BONK', label: 'BONK', img: cryptoLogos.BONK, mint: addresses.bonk, decimals: 5 },
    { value: 'BORG', label: 'BORG', img: cryptoLogos.BORG, mint: addresses.borg, decimals: 9 },
    { value: 'BSOL', label: 'BSOL', img: cryptoLogos.BSOL, mint: addresses.bsol, decimals: 9 },
    { value: 'CHILLGUY', label: 'CHILLGUY', img: cryptoLogos.CHILLGUY, mint: addresses.chillguy, decimals: 6 },
    { value: 'CLOUD', label: 'CLOUD', img: cryptoLogos.CLOUD, mint: addresses.cloud, decimals: 9 },
    { value: 'CROWN', label: 'CROWN', img: cryptoLogos.CROWN, mint: addresses.crown, decimals: 9 },
    { value: 'DASHA', label: 'DASHA', img: cryptoLogos.DASHA, mint: addresses.dasha, decimals: 6 },
    { value: 'DRIFT', label: 'DRIFT', img: cryptoLogos.DRIFT, mint: addresses.drift, decimals: 6 },
    { value: 'GRASS', label: 'GRASS', img: cryptoLogos.GRASS, mint: addresses.grass, decimals: 9 },
    { value: 'GUAC', label: 'GUAC', img: cryptoLogos.GUAC, mint: addresses.guac, decimals: 5 },
    { value: 'HNT', label: 'HNT', img: cryptoLogos.HNT, mint: addresses.hnt, decimals: 8 },
    { value: 'HONEY', label: 'HONEY', img: cryptoLogos.HONEY, mint: addresses.honey, decimals: 9 },
    { value: 'JITOSOL', label: 'JITOSOL', img: cryptoLogos.JITOSOL, mint: addresses.jitosol, decimals: 9 },
    { value: 'JLP', label: 'JLP', img: cryptoLogos.JLP, mint: addresses.jlp, decimals: 6 },
    { value: 'JTO', label: 'JTO', img: cryptoLogos.JTO, mint: addresses.jto, decimals: 9 },
    { value: 'JUP', label: 'JUP', img: cryptoLogos.JUP, mint: addresses.jup, decimals: 6 },
    { value: 'KMNO', label: 'KMNO', img: cryptoLogos.KMNO, mint: addresses.kmno, decimals: 6 },
    { value: 'LOCKIN', label: 'LOCKIN', img: cryptoLogos.LOCKIN, mint: addresses.lockin, decimals: 9 },
    { value: 'LST', label: 'LST', img: cryptoLogos.LST, mint: addresses.lst, decimals: 9 },
    { value: 'MEW', label: 'MEW', img: cryptoLogos.MEW, mint: addresses.mew, decimals: 5 },
    { value: 'MICHI', label: 'MICHI', img: cryptoLogos.MICHI, mint: addresses.michi, decimals: 6 },
    { value: 'MINI', label: 'MINI', img: cryptoLogos.MINI, mint: addresses.mini, decimals: 6 },
    { value: 'MNDE', label: 'MNDE', img: cryptoLogos.MNDE, mint: addresses.mnde, decimals: 9 },
    { value: 'MOTHER', label: 'MOTHER', img: cryptoLogos.MOTHER, mint: addresses.mother, decimals: 6 },
    { value: 'MSOL', label: 'MSOL', img: cryptoLogos.MSOL, mint: addresses.msol, decimals: 9 },
    { value: 'MUMUSOL', label: 'MUMUSOL', img: cryptoLogos.MUMU, mint: addresses.mumu, decimals: 6 },
    { value: 'MYRO', label: 'MYRO', img: cryptoLogos.MYRO, mint: addresses.myro, decimals: 9 },
    { value: 'NATIXNETWORK', label: 'NATIXNETWORK', img: cryptoLogos.NATIX, mint: addresses.natix, decimals: 6 },
    { value: 'NEONEVM', label: 'NEONEVM', img: cryptoLogos.NEON, mint: addresses.neon, decimals: 9 },
    { value: 'NOS', label: 'NOS', img: cryptoLogos.NOS, mint: addresses.nos, decimals: 6 },
    { value: 'ORCA', label: 'ORCA', img: cryptoLogos.ORCA, mint: addresses.orca, decimals: 6 },
    { value: 'ORE', label: 'ORE', img: cryptoLogos.ORE, mint: addresses.ore, decimals: 11 },
    { value: 'PONKE', label: 'PONKE', img: cryptoLogos.PONKE, mint: addresses.ponke, decimals: 9 },
    { value: 'POPCAT', label: 'POPCAT', img: cryptoLogos.POPCAT, mint: addresses.popcat, decimals: 9 },
    { value: 'PYTH', label: 'PYTH', img: cryptoLogos.PYTH, mint: addresses.pyth, decimals: 6 },
    { value: 'RAY', label: 'RAY', img: cryptoLogos.RAY, mint: addresses.ray, decimals: 6 },
    { value: 'RENDER', label: 'RENDER', img: cryptoLogos.RENDER, mint: addresses.render, decimals: 8 },
    { value: 'RETARDIO', label: 'RETARDIO', img: cryptoLogos.RETARDIO, mint: addresses.retardio, decimals: 6 },
    { value: 'SADHAMSTER', label: 'SADHAMSTER', img: cryptoLogos.HAMMY, mint: addresses.hammy, decimals: 6 },
    { value: 'SELFIEDOGCOIN', label: 'SELFIEDOGCOIN', img: cryptoLogos.SELFIE, mint: addresses.selfie, decimals: 6 },
    { value: 'SHDW', label: 'SHDW', img: cryptoLogos.SHDW, mint: addresses.shdw, decimals: 9 },
    { value: 'SIGMA', label: 'SIGMA', img: cryptoLogos.SIGMA, mint: addresses.sigma, decimals: 6 },
    { value: 'SPX6900', label: 'SPX6900', img: cryptoLogos.SPX6900, mint: addresses.spx6900, decimals: 8 },
    { value: 'TNSR', label: 'TNSR', img: cryptoLogos.TNSR, mint: addresses.tnsr, decimals: 9 },
    { value: 'UXD', label: 'UXD', img: cryptoLogos.UXD, mint: addresses.uxd, decimals: 6 },
    { value: 'VCHF', label: 'VCHF', img: cryptoLogos.VCHF, mint: addresses.vchf, decimals: 9 },
    { value: 'VEUR', label: 'VEUR', img: cryptoLogos.VEUR, mint: addresses.veur, decimals: 9 },
    { value: 'W', label: 'W', img: cryptoLogos.W, mint: addresses.w, decimals: 6 },
    { value: 'WEN', label: 'WEN', img: cryptoLogos.WEN, mint: addresses.wen, decimals: 5 },
    { value: 'WIF', label: 'WIF', img: cryptoLogos.WIF, mint: addresses.wif, decimals: 6 },
    { value: 'WXM', label: 'WXM', img: cryptoLogos.WXM, mint: addresses.wxm, decimals: 9 },
    { value: 'XBG', label: 'XBG', img: cryptoLogos.XBG, mint: addresses.xbg, decimals: 9 },
    { value: 'ZEREBRO', label: 'ZEREBRO', img: cryptoLogos.ZEREBRO, mint: addresses.zerebro, decimals: 6 },
    { value: 'ZEX', label: 'ZEX', img: cryptoLogos.ZEX, mint: addresses.zex, decimals: 6 },
    { value: 'GIGASOL', label: 'GIGASOL', img: cryptoLogos.GIGASOL, mint: addresses.gigasol, decimals: 5 },
    { value: 'TOOKERSOL', label: 'TOOKERSOL', img: cryptoLogos.TOOKERSOL, mint: addresses.tookersol, decimals: 9 },
    { value: 'GOATSOL', label: 'GOATSOL', img: cryptoLogos.GOATSOL, mint: addresses.goatsol, decimals: 6 },
    { value: 'BRETTSOL', label: 'BRETTSOL', img: cryptoLogos.BRETTSOL, mint: addresses.brettsol, decimals: 9 },
    { value: 'MOODENGSOL', label: 'MOODENGSOL', img: cryptoLogos.MOODENGSOL, mint: addresses.moodengsol, decimals: 6 },
    { value: 'TREMPSOL', label: 'TREMPSOL', img: cryptoLogos.TREMPSOL, mint: addresses.trempsol, decimals: 9 },
    { value: 'HARAMBESOL', label: 'HARAMBESOL', img: cryptoLogos.HARAMBESOL, mint: addresses.harambesol, decimals: 9 },
    { value: 'METASOL', label: 'METASOL', img: cryptoLogos.METASOL, mint: addresses.metasol, decimals: 9 },
    { value: 'BORGY', label: 'BORGY', img: cryptoLogos.BORGY, mint: addresses.borgy, decimals: 5 },
    { value: 'BITCOINPOTTER', label: 'BITCOINPOTTER', img: cryptoLogos.BITCOINPOTTER, mint: addresses.bitcoinpotter, decimals: 8 },
    { value: 'IO', label: 'IO', img: cryptoLogos.IO, mint: addresses.io, decimals: 8 },
    { value: 'ME', label: 'ME', img: cryptoLogos.ME, mint: addresses.me, decimals: 6 },
    { value: 'PNUT', label: 'PNUT', img: cryptoLogos.PNUT, mint: addresses.pnut, decimals: 6 },
    { value: 'SCF', label: 'SCF', img: cryptoLogos.SCF, mint: addresses.scf, decimals: 6 },
    { value: 'USA', label: 'USA', img: cryptoLogos.USA, mint: addresses.usa, decimals: 6 },
    { value: 'PENGU', label: 'PENGU', img: cryptoLogos.PENGU, mint: addresses.pengu, decimals: 6},
    { value: 'AGA', label: 'AGA', img: cryptoLogos.AGA, mint: addresses.aga, decimals: 9},
    { value: 'SOLID', label: 'SOLID', img: cryptoLogos.SOLID, mint: addresses.solid, decimals: 9},
    { value: 'CHONKY', label: 'CHONKY', img: cryptoLogos.CHONKY, mint: addresses.chonky, decimals: 6},
    { value: 'MOE', label: 'MOE', img: cryptoLogos.MOE, mint: addresses.moe, decimals: 6},
    { value: 'AGENTSAI', label: 'AGENTSAI', img: cryptoLogos.AGENTSAI, mint: addresses.agentSai, decimals: 9},
    { value: 'DOGAMI', label: 'DOGAMI', img: cryptoLogos.DOGAMI, mint: addresses.dogami, decimals: 5},
    { value: 'SMARDEX', label: 'SMARDEX', img: cryptoLogos.SMARDEX, mint: addresses.smartdex, decimals: 8},
    { value: 'AIPUMP', label: 'AIPUMP', img: cryptoLogos.AIPUMP, mint: addresses.aiPump, decimals: 9},
    { value: 'MATES', label: 'MATES', img: cryptoLogos.MATES, mint: addresses.mates, decimals: 6},
    { value: 'FARTCOIN', label: 'FARTCOIN', img: cryptoLogos.FARTCOIN, mint: addresses.fartcoin, decimals: 6},
    { value: 'MAGA', label: 'MAGA', img: cryptoLogos.MAGA, mint: addresses.maga, decimals: 8},
    { value: 'PIPPIN', label: 'PIPPIN', img: cryptoLogos.PIPPIN, mint: addresses.pippin, decimals: 6},
    { value: 'CREATE', label: 'CREATE', img: cryptoLogos.CREATE, mint: addresses.create, decimals: 6},
    { value: 'NEUR', label: 'NEUR', img: cryptoLogos.NEUR, mint: addresses.neur, decimals: 6},
    { value: 'GRIFFAIN', label: 'GRIFFAIN', img: cryptoLogos.GRIFFAIN, mint: addresses.griffain, decimals: 6},
    { value: 'GRIFT', label: 'GRIFT', img: cryptoLogos.GRIFT, mint: addresses.grift, decimals: 6},
    { value: 'GNZ', label: 'GNZ', img: cryptoLogos.GNZ, mint: addresses.gnz, decimals: 6},
    { value: 'TRUMP', label: 'TRUMP', img: cryptoLogos.TRUMP, mint: addresses.trump, decimals: 6},
    { value: 'MELANIA', label: 'MELANIA', img: cryptoLogos.MELANIA, mint: addresses.melania, decimals: 6},
    { value: 'SNAI', label: 'SNAI', img: cryptoLogos.SNAI, mint: addresses.snai, decimals: 6},
    { value: 'AVA', label: 'AVA', img: cryptoLogos.AVA, mint: addresses.ava, decimals: 6},
    { value: 'SEND', label: 'SEND', img: cryptoLogos.SEND, mint: addresses.send, decimals: 6},
    { value: 'BUTTHOLE', label: 'BUTTHOLE', img: cryptoLogos.BUTTHOLE, mint: addresses.butthole, decimals: 6},
    { value: 'YNE', label: 'YNE', img: cryptoLogos.YNE, mint: addresses.yne, decimals: 6},
    { value: 'ARXIV', label: 'ARXIV', img: cryptoLogos.ARXIV, mint: addresses.arxiv, decimals: 6},
    { value: 'CHATOSHI', label: 'CHATOSHI', img: cryptoLogos.CHATOSHI, mint: addresses.chatoshi, decimals: 6},
    { value: 'REGENT', label: 'REGENT', img: cryptoLogos.REGENT, mint: addresses.regent, decimals: 6},
    { value: 'FWOG', label: 'FWOG', img: cryptoLogos.FWOG, mint: addresses.fwog, decimals: 6},
    { value: 'SOLA', label: 'SOLA', img: cryptoLogos.SOLA, mint: addresses.sola, decimals: 6},
    { value: 'ALCH', label: 'ALCH', img: cryptoLogos.ALCH, mint: addresses.alch, decimals: 6},
    { value: 'TANK', label: 'TANK', img: cryptoLogos.TANK, mint: addresses.tank, decimals: 6},
    { value: 'FXN', label: 'FXN', img: cryptoLogos.FXN, mint: addresses.fxn, decimals: 6},
    { value: 'T3AI', label: 'T3AI', img: cryptoLogos.T3AI, mint: addresses.t3ai, decimals: 9},
    { value: 'TAI', label: 'TAI', img: cryptoLogos.TAI, mint: addresses.tai, decimals: 9},
    { value: 'NPC', label: 'NPC', img: cryptoLogos.NPC, mint: addresses.npc, decimals: 8},
    { value: 'CVAI', label: 'CVAI', img: cryptoLogos.CVAI, mint: addresses.cvai, decimals: 9},
    { value : 'CAR', label: 'CAR', img: cryptoLogos.CAR, mint: addresses.car, decimals: 6},
    { value : 'JELLYJELLY', label: 'JELLYJELLY', img: cryptoLogos.JELLYJELLY, mint: addresses.jellyJelly, decimals: 6},
    { value : 'HYPER', label: 'HYPER', img: cryptoLogos.HYPER, mint: addresses.hyper, decimals: 6},
    { value : 'BULLY', label: 'BULLY', img: cryptoLogos.BULLY, mint: addresses.bully, decimals: 6},
    { value : 'PRCL', label: 'PRCL', img: cryptoLogos.PRCL, mint: addresses.prcl, decimals: 6},
    { value : 'MEDIA', label: 'MEDIA', img: cryptoLogos.MEDIA, mint: addresses.media, decimals: 6},
    { value : 'ZEUS', label: 'ZEUS', img: cryptoLogos.ZEUS, mint: addresses.zeus, decimals: 6},
    { value : 'VINE', label: 'VINE', img: cryptoLogos.VINE, mint: addresses.vine, decimals: 6},
    { value : 'BLOCK', label: 'BLOCK', img: cryptoLogos.BLOCK, mint: addresses.block, decimals: 6},
    { value : 'COOL', label: 'COOL', img: cryptoLogos.COOL, mint: addresses.cool, decimals: 6},
    { value : 'BOME', label: 'BOME', img: cryptoLogos.BOME, mint: addresses.bome, decimals: 6},
    { value : 'ARC', label: 'ARC', img: cryptoLogos.ARC, mint: addresses.arc, decimals: 6},
    { value : 'VGBP', label: 'VGBP', img: cryptoLogos.VGBP, mint: addresses.vgbp, decimals: 9},
    { value : 'TADA', label: 'TADA', img: cryptoLogos.TADA, mint: addresses.tada, decimals: 8},
    { value : 'ACT', label: 'ACT', img: cryptoLogos.ACT, mint: addresses.act, decimals: 6},
    { value : 'VNXAU', label: 'VNXAU', img: cryptoLogos.VNXAU, mint: addresses.vnxau, decimals: 9},
    { value : 'CARV', label: 'CARV', img: cryptoLogos.CARV, mint: addresses.carv, decimals: 6},
    { value : 'BMT', label: 'BMT', img: cryptoLogos.BMT, mint: addresses.bmt, decimals: 9},
    { value : 'LAYER', label: 'LAYER', img: cryptoLogos.LAYER, mint: addresses.layer, decimals: 9},
    { value : 'AIDD', label: 'AIDD', img: cryptoLogos.AIDD, mint: addresses.aidd, decimals: 9},
];

const App: FC = () => {
    return (
        <ChakraProvider theme={theme}>
            <Web3Provider>
                <Main />
            </Web3Provider>
        </ChakraProvider>
    );
};
export default App;
